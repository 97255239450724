import React, { useState, useEffect } from "react"
import TutorialLayout from "../../components/TutorialLayout"
import IMG from "../../images/Setp_one.svg"
import APPSTORE from "../../images/Appstore.svg"
import GPLAY from "../../images/button_google_play.svg"
import { Link } from "gatsby"

const Second = () => {
  const [showAppStore, setShowAppStore] = useState(false)
  const [showGooglePlay, setShowGooglePlay] = useState(false)

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera

    if (/android/i.test(userAgent)) {
      setShowGooglePlay(true)
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setShowAppStore(true)
    } else {
      setShowAppStore(true)
      setShowGooglePlay(true)
    }
  }, [])

  return (
    <TutorialLayout
      imageSrc={IMG}
      step="1"
      title="Instalează aplicația mobilă MyAra"
      description="Pentru a deschide containerul direct cu telefonul, trebuie să ai instalată aplicația mobilă MyAra. "
      buttonLink="/salut/stepTwo"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {showAppStore && (
          <Link to="https://apps.apple.com/ro/app/ara/id1511186313">
            <img src={APPSTORE} alt="App Store" style={{ height: 48 }} />
          </Link>
        )}
        {showGooglePlay && (
          <Link to="https://play.google.com/store/apps/details?id=com.persidius.ara">
            <img src={GPLAY} alt="App Store" style={{ height: 48 }} />
          </Link>
        )}
      </div>
    </TutorialLayout>
  )
}

export default Second
